import { useQuery } from "@tanstack/vue-query";

export const usePosQuery = () => {
  const axios = useAxios();
  const query = useQuery({
    queryKey: ["pos"],
    queryFn: async () => {
      const { data } = await axios.get("pos");
      const { pos } = data.data;

      return pos;
    },
    staleTime: Infinity,
  });

  return { ...query };
};
